
























































































































































































































































































































































































.smartsearch
  .text-subtitle1
    font-size .75em
    line-height 1em
  .text-subtitle2
    font-size .78em
    line-height 1.5em

.nearby-icon
  color #fff
.nearby-icon-contrast
  color #222
.nearby-item
  .text-subtitle2
    font-size .75em
.bookTypeButton
  font-size .75em
.bookNow
  .text-subtitle2
    font-weight 400
    font-size .75em
    line-height 1.35em
.mobileApp
  position absolute
  left 0
  right 0
  font-size .75em
  text-align center
.smartsearchFooter
  height 48px
  transition height .5s
  overflow hidden
  &.expanded
    height 176px
.text-small
  font-size 0.8rem
